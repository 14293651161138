import img1 from '../assets/downloadicon.png';
import img2 from '../assets/gameicon.png';
import img3 from '../assets/usersicon.png';

const Data = [
    {
        photo: img1, title: "10+ MILLION", description: "Downloads",
    },
    {
        photo: img2, title: "50+", description: "Game Launches",
    },
    {
        photo: img3, title: "10+ MILLION", description: "Users",
    },
];

const Join = () => {
    return (
        <section id="Join">
            <join className="container">
                <div className="inner-container">
                    <div className="join-content">
                        <div className="join-heading">
                            <h1>OUR STATS</h1>
                        </div>
                        <div className="join-text">
                            <p>
                                Our mission to entertain the world goes <br />
                                beyond gaming. Our team create <br />
                                innovative and entertaining apps for <br />
                                everyday life.
                            </p>
                        </div>
                        <div className="inline-container">
                            {Data.map((item, index) => (
                                <div key={index} className="stats">
                                    <img style={{paddingTop: 30}} src={item.photo} alt="" />
                                    <h3>{item.title}</h3>
                                    <h5>{item.description}</h5>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </join>
        </section>
    );
}

export default Join;