
import './App.css';
import './Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';
import TopHits from './components/TopHits';
import EntertainingApp from './components/EntertainingApp';
import Team from './components/Team';
import Join from './components/join';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className="body">
      <Header />
      <TopHits />
      <EntertainingApp />
      <Team />
      <Join />
      <ContactUs />
    </div>
  );
}

export default App;
