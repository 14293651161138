import logo from '../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';


const Navigation = () => {

  const handleClick = (e, sectionId) => {
    e.preventDefault(); // Prevent the default anchor tag behavior
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
        offset: -70,
      });
    }
  };

  return (

    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3 navbarBg" style={{ background: 'transparent;' }}>
          <Container fluid>
            <Navbar.Brand href="#" id="logo-heading"><img className='inline' src={logo} alt='' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header id='close' closeButton>
                <Offcanvas.Title className='mbl-title' id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <span style={{ color: '#ffffff', fontSize: '40px' }}>BIG LABZ</span>
                </Offcanvas.Title>*
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto links">
                  <Nav.Link className="nav-items text-nowrap" href="#TopHits" onClick={(e) => handleClick(e, 'TopHits')}>OUR VISION</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#EntertainingApp" onClick={(e) => handleClick(e, 'EntertainingApp')}>OUR PRODUCTS</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#Team" onClick={(e) => handleClick(e, 'Team')}>OUR TEAM</Nav.Link>
                  <Nav.Link className="nav-items text-nowrap" href="#footerContainer" onClick={(e) => handleClick(e, 'footerContainer')}>CONTACT US</Nav.Link>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navigation;