

const TopHits = () => {
    return (
        <section id="TopHits">
            <div className="container">
                <div className="inner-container">
                    <div className="left-TopHitscontainer">
                        <div className="TopHits-heading">
                            <h1>
                                OUR VISION
                            </h1>
                        </div>
                        <div className="TopHits-text">
                            <p>
                                Dive into our captivating mobile gaming portfolio, where every tap unlocks a world of excitement and adventure. 
                                From pulse-pounding action to brain-teasing puzzles, our diverse range of games offers something for every gamer's taste. 
                                Immerse yourself in stunning visuals, intuitive controls, and immersive gameplay designed to keep you entertained 
                                for hours on end. Whether you're a casual player or a hardcore enthusiast, our mobile games guarantee endless thrills right 
                                at your fingertips. Explore, conquer, and dominate – welcome to the future of mobile gaming with us.
                            </p>
                        </div>
                    </div>
                    <div className="right-TopHitscontainer">
                        <div className="right-image">
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
        </section>
    );
}

export default TopHits;