import img1 from '../assets/member1.png';
import member1 from '../assets/teammember-1.jpeg';
import member2 from '../assets/teammember-2.jpeg';
import member3 from '../assets/teammember-3.jpeg';
import member4 from '../assets/teammember-4.jpeg';
import member5 from '../assets/teammember-5.jpeg';
import member6 from '../assets/teammember-6.png';

const Team = () => {
    return (
        <section id="Team">
            <div className="container">

                <div className="teamcontent">
                    <div className="heading">
                        <h1>MEET THE SUPERSTARS <br /> BEHIND THE <br /> BIG LABZ</h1>
                    </div>
                    <div className="content">
                        <p></p>
                    </div>
                </div>

                <div className="inner-container">
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member2} alt="member" />
                            <div className="info">
                                <h2>Hassan Javaid</h2>
                                <h3>CEO</h3>
                                <p>Hassan has 27 years of experience for making decisions, managing operations & resources, and being main point of communication between the board of directors and corporate operations.</p>
                            </div>
                            <div className="team-btn">
                                <a href="https://www.linkedin.com/in/hassan-jawaid-83a61621/" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member5} alt="member" />
                            <div className="info">
                                <h2>Kashif Rehman</h2>
                                <h3>CTO</h3>
                                <p>Kashif is a DevOps Architect with over 18 years of experience in IT industry. Specialized in adeptly designs 
                                    resilient and scalable solutions across multiple Cloud platforms.</p>
                            </div>
                            <div className="team-btn">
                                <a href="https://www.linkedin.com/in/devopsarchitect/" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member3} alt="member" />
                            <div className="info">
                                <h2>Faizan Mubashar</h2>
                                <h3>CFO</h3>
                                <p>Faizan with over 15 years of experience. He lead the charge in crafting breathtaking visual experiences that captivate and engage players worldwide.</p>
                            </div>
                            <div className="team-btn">
                                <a href="https://www.linkedin.com/in/faizanmubasher/" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="belowContainer">
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member6} alt="member" />
                            <div className="info">
                                <h2>Mobeen Afzal</h2>
                                <h3>Director Technology</h3>
                                <p>Mobeen has Vast experience of developing high end mobile games with market experience of 12 years.
                                    He is one of the leading mobile game developer and entrepenaur</p>
                            </div>
                            <div className="team-btn">
                                <a href="https://www.linkedin.com/in/iammobeenafzal/" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member4} alt="member" />
                            <div className="info">
                                <h2>Haroon Akhter</h2>
                                <h3>Product owner</h3>
                                <p>A Presidential Award holder with 18+ years of experience spanning HR, PR, and Technology. He's experts in Building Teams for products and services in Gaming, Cyber Security and Mobile Apps.</p>
                            </div>
                            <div className="team-btn">
                                <a href="https://www.linkedin.com/in/haroonakhtar/" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                    <div className="inline-div">
                        <div className="teaminfo">
                            <img src={member1} alt="member" />
                            <div className="info">
                                <h2>Ammar Aslam</h2>
                                <h3>Director Artwork</h3>
                                <p>Ammar has 15+ years of experience. As Chief Product Officer, He always stand at the forefront of innovation and excellence, masterfully steering the evolution of our products.</p>
                            </div>
                            <div className="team-btn">
                                <a href="www.linkedin.com/in/ammar-aslam-ceo-bigentities" target="_blank"><h2>SEE MORE</h2></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;