

const EntertainingApp = () => {
    return (
        <section id="EntertainingApp">
            <div className="container">
                <div className="inner-container">
                    <div className="left-EntertainingAppcontainer">
                    </div>

                    <div className="right-EntertainingAppcontainer">
                        <div className="right-image">
                            <div className="EntertainingApp-heading">
                                <h1>
                                    OUR PRODUCTS
                                </h1>
                            </div>
                            <div className="EntertainingApp-text">
                                <p>
                                    Experience gaming like never before with our cutting-edge mobile products. Dive into a world 
                                    where every swipe, tap, and tilt takes you on an exhilarating journey. From high-octane 
                                    racing games to immersive RPG adventures, our mobile lineup offers a diverse range of titles 
                                    to satisfy every gamer's craving. With stunning graphics, intuitive controls, and seamless 
                                    multiplayer functionality, our games redefine mobile entertainment. Whether you're competing 
                                    with friends or embarking on solo quests, our gaming products deliver excitement, challenge, 
                                    and endless fun right to the palm of your hand. Join us and discover the next level of mobile 
                                    gaming excellence.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
        </section>
    );
}

export default EntertainingApp;