import footerLogo from '../assets/footerLogo.png';
import playstore from '../assets/playstore.png';
import appstore from '../assets/appstore.png';

import icon1 from '../assets/facebook.png';
import icon2 from '../assets/insta.png';
import icon3 from '../assets/linkedin.png';
import icon4 from '../assets/discord.png';
import icon5 from '../assets/twitter.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const ContactUs = () => {
    return (
        <section id="footerContainer">
            <div className="container">
                <div className="inner-container">


                    <div className="leftfooter-container">
                        <div className="footerlogo">
                            <img src={footerLogo} alt="footerlogo" />
                        </div>
                    </div>
                    <div className="rightfooter-container">
                        <div className="footerheading">
                            <h1>CONTACT US</h1>
                        </div>
                        <div className="footertext">
                            <p>
                                We’re here to help and answer any question <br />
                                you might have. We look
                                forward to hearing from you.
                            </p>
                        </div>
                        <div className="button-container">
                            <div className="footerbtn">
                                <a href="mailto:support@biglabz.com" target="_blank" rel="noopener noreferrer"><button className='btn-img'><FontAwesomeIcon icon={faEnvelope} /> MAIL US</button></a>
                            </div>
                            <div className="footerbtn">
                                <a href="https://wa.me/923151757222" target="_blank"><button className='btn-img'><FontAwesomeIcon icon={faPhone} /> CALL US</button></a>
                            </div>
                        </div>
                        <div class="download-icons">
                            <div class="download-btn">
                                <a href="#" target='_blank' rel="noopener noreferrer"><img src={playstore} alt='playstore' /></a>
                            </div>
                            <div class="download-btn">
                                {/*<a href="#" target="_blank" rel="noopener noreferrer"><img src={appstore} alt='appstore' /></a>*/}
                                <Popup trigger={<button style={{background: 'transparent', border: 'none'}}><img src={appstore} alt='appstore' /></button>} position="center">
                                    <div style={{ textAlign: 'center', padding: 30 }}>Coming Soon</div>
                                </Popup>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>


                </div>


                <div class="copy-icons-container">
                    <div class="copy-container">
                        <p>&copy; 2024 Big Labz. All rights reserved. <a href="#" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>Privacy Policy</a></p>
                    </div>
                    <div class="icons-container">
                        <div class="social-icons">
                            <p>follow Us</p>
                        </div>
                        <div class="social-icons">
                            <a href="#" target="_blank" rel="noopener noreferrer"><img src={icon1} alt='facebook' /></a>
                        </div>
                        <div class="social-icons">
                            <a href="#" target="_blank" rel="noopener noreferrer"><img src={icon2} alt='instagram' /></a>
                        </div>
                        <div class="social-icons">
                            <a href="#" target="_blank" rel="noopener noreferrer"><img src={icon5} alt='twitter' /></a>
                        </div>
                        <div class="social-icons">
                            <a href="https://www.linkedin.com/company/biglabz/" target="_blank" rel="noopener noreferrer"><img src={icon3} alt='linkedin' /></a>
                        </div>
                        <div class="social-icons">
                            <a href="#" target="_blank" rel="noopener noreferrer"><img src={icon4} alt='discord' /></a>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>


            </div>
        </section>
    );
}

export default ContactUs;