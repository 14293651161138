import Navigation from './Navigation';

const Header = () => {
    return (
        <section id="header">
            <Navigation />
            <div className="container">
                <div className="inner-container">
                    <div className="left-container">
                    </div>

                    <div className="right-container">
                        <div className="right-image">
                            <div className="header-heading">
                                <h1>
                                    Unleash Your Ultimate Gaming Experience!
                                </h1>
                            </div>
                            <div className="header-btn">
                                <a href="#" target="_blank"><h2>DISCOVER MORE</h2></a>
                            </div>
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
        </section>
    );
}

export default Header;